import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutSimpleFooter from '../../components/LayoutSimpleFooter'
import SEO from '../../components/SEO'
import FormEbook from '../../components/FormEbook'
import classNames from 'classnames/bind'
import * as EbookPageStyles from '../../styles/EbookPage.css'

const cx = classNames.bind(EbookPageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY || ''
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <LayoutSimpleFooter pageContext={pageContext}>
        <SEO
          title="効果的なLPOの進め⽅/KARTE Blocksを活⽤したLPO最新事例の資料請求"
          description="効果的なLPOの進め⽅/KARTE Blocksを活⽤したLPO最新事例の資料請求ページです。"
          path="/ebook/lpo/"
        />

        <main className={cx('Outer')}>
          <div className={cx('Container')}>
            <section className={cx('Content')}>
              <h1 className={cx('Headline')}>
                効果的なLPOの進め⽅/
                <br />
                KARTE Blocksを活⽤したLPO最新事例
              </h1>

              <StaticImage src="../../images/ebook/lpo-thumbnail.jpg" alt="資料の表紙" />

              <section className={cx('Details')}>
                <h2 className={cx('Details_Headline')}>資料の内容</h2>
                <ol className={cx('Details_Outline')}>
                  <li className={cx('Details_Item')}>効果的なLPO(ランディングページ最適化)の進め方</li>
                  <li className={cx('Details_Item')}>ABテストで検証する3つの内容</li>
                  <li className={cx('Details_Item')}>ABテストを設計・実施する6つのポイント</li>
                  <li className={cx('Details_Item')}>KARTE Blocksを活用した LPO事例</li>
                </ol>
              </section>
            </section>

            <FormEbook actionUrl="https://deal.karte.io/l/819613/2022-07-20/67rhc" actionName="blockskarteio/ebook/lpo/" />
          </div>
        </main>
      </LayoutSimpleFooter>
    </GoogleReCaptchaProvider>
  )
}
